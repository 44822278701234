import React, {useEffect, useState, useRef} from "react";

// Hooks
import {useLocation} from 'react-router-dom';
import {useDispatch, useSelector} from 'react-redux';
import {updateGlobalStorePage, updateGlobalStorePageBis} from '../Store/action';
import useApi from '../Hooks/useApi';

// Components
import {LoaderPage} from '../Components/loader';
import Metatags from '../Layout/metas-tags/meta-tags';
import Share from "../Components/share";
import Faq from "../Components/faq";
import AlertPush from "../Components/alert-push";
import Blogposts from "../Components/blog-posts";
import ElevatorPush from "../Components/elevator-push";
//import Ambassador from "../Components/ambassador";
import Offers from "../Components/offers";
import CLink from "../Components/link";
import ScoringPush from "../Components/scoring-push";
import PostulateButton from "../Components/postulate-button";
import LikeButton from "../Components/like-button";
import Video from "../Components/video";
import {CaaskOfferDetails} from "../Components/caask";


const TemplateOfferDetails = ({_uid}) => {
    const lang = useSelector(state => state.global).lang;

    const restricted = useSelector(state => state.global).isRestricted;
    const {global: {config:{active_map, active_news}, colors, header: {display_elevator}}} = useSelector(state => state.global);
    const cookiesFilters = useSelector(state => state.global).filters;
    const params = cookiesFilters && cookiesFilters.offer;
    const customPrimaryColor = colors?.primary;
    const haveCustomPrimaryColor = !!customPrimaryColor;
    const search = window.location.search;
    const paramsB = new URLSearchParams(search);
    const restrictedBrands = paramsB.get('tax_brands');

    const [dataFetch, isLoaded] = useApi({name: 'posts', _uid: _uid});

    let dispatch = useDispatch();
    useEffect(() => {
        dispatch(updateGlobalStorePage('t-offer-details'));
        dispatch(updateGlobalStorePageBis(''));
    }, [dispatch]); // isLoaded


    const renderMarkup = (markup) => {
        return {__html: markup};
    };

    const [reRenderLikeComponents, setReRenderLikeComponents] = useState(false);
    const [update, setUpdate] = useState(false);
    const [partner, setPartner] = useState();

    const searchParams = useLocation().search;

    const updateLikeComponents = () => {
        setReRenderLikeComponents(true);
    };

    useEffect(() => {
        setUpdate(true);
        setTimeout(() => {
            setUpdate(false);
            setReRenderLikeComponents(false);
        }, 100);
    }, [reRenderLikeComponents]);


    let {m991} = useSelector(state => state.breakpoint);
    const [mobile, setMobile] = useState(m991);

    useEffect(() => {
        if (m991) {
            setMobile(true);
        } else {
            setMobile(false);
        }
    }, [m991]);

    const [searchPath, setSearchPath] = useState('');
    useEffect(() => {
        let index = 0;
        let path = '';
        if (params) {
            for (const filter in params) { // loop sur les params
                if (filter !== 'lang' && filter !== 'from' && filter !== 'size' && filter !== 'userID' && params[filter] && params[filter] !== '') {// je ne prends que les paramètres remplis sauf lang et userID
                    if (index === 0) {
                        path = `?${filter}=${params[filter]}`;
                    } else {
                        path += `&${filter}=${params[filter]}`
                    }
                    index++;
                }
            }
        }
        setSearchPath(path);
    }, [params]);

    useEffect(() => {
        if (searchParams !== '') {
            let searchArray = searchParams.split('&');
            searchArray.map((term, index) => {
                let searchTerm = term.split('=');
                let taxonomy = searchTerm[0];
                if (index === 0) taxonomy = searchTerm[0].replace('?', '');
                if (taxonomy === 'sType') setPartner(`&sType=${searchTerm[1]}`);
            });
        }
    }, [searchParams]);

    function renderPage() {
        const {metas, content: {microdatas, dynamics_blocs, top: {post_id, opening_id, job_number, back, title, company, criteria, postulate, liked, sharer}, main: {text, postulate: /*mainPostulatex,*/ faq, youtube_video_id}, aside: {blogposts, job_alert, ambassador, elevator, cv/*, address */}, bottom: {jobs_offer, image}}} = dataFetch;

        let textSplit;
        if(text){
            textSplit = text.replace(/<h2>/gi, '§<h2>');
            textSplit = textSplit.split('§');
        }

        if (window.tarteaucitron && window.tarteaucitron.datadriven) {
            // Data driven cookies
            document.ddSiteAnnId = opening_id;
            document.ddAnnRef = job_number;
            document.ddSiteCkie = window.tarteaucitron.datadriven.state ? 0 : 1;
        }

        const customColorStyles = haveCustomPrimaryColor ? {color: customPrimaryColor} : {};
        const customTitleStyles = haveCustomPrimaryColor ? {
            color: customPrimaryColor,
            borderColor: customPrimaryColor
        } : {};
        const customBorderStyles = haveCustomPrimaryColor ? {borderColor: customPrimaryColor} : {};

        return (
            <>
                <Metatags {...metas} microdatas={microdatas}/>

                <CaaskOfferDetails/>

                <main className="main">

                    <div className="main__header" data-color="purple">
                        <div className="main__header-intro">
                            <div className="container">
                                <div className="main__header-introFlexText">

                                    <CLink {...back}
                                           url={`${restricted ? back.url + '?external=true&tax_brands=' + restrictedBrands : back.url + searchPath}`}
                                           className={`c-link c-link--back`} style={customColorStyles}>
                                        <i className="icon-arrow-right"></i>
                                        <span dangerouslySetInnerHTML={renderMarkup(back.title)}
                                              style={customColorStyles}></span>
                                    </CLink>


                                    <h1 className="title-50" dangerouslySetInnerHTML={renderMarkup(title)}
                                        style={customTitleStyles}></h1>
                                </div>
                                <div className="main__header-introFlex--offer" style={customBorderStyles}>
                                    {(company && company.image.src) &&
                                        (
                                            (company.link && company.link.length > 0) ?
                                                <CLink {...company.link} className={``}>
                                                    <img src={company.image.src} alt={company.image.alt}/>
                                                </CLink>
                                                :
                                                <img src={company.image.src} alt={company.image.alt}/>
                                        )
                                    }

                                    <div className="main__header-introFlex">
                                        {(sharer && Object.keys(sharer).length > 0) &&
                                            <div className="main__header-introFlexShare">
                                                <Share {...sharer} />
                                            </div>
                                        }

                                        <div className="main__header-introFlexText">
                                              <ul className="c-offer-infos">
                                                {criteria.localisation &&
                                                <li><i className="icon-location"></i>{criteria.localisation}</li>
                                                }
                                                {criteria.contract &&
                                                <li><i className="icon-contract"></i>{criteria.contract}</li>
                                                }
                                                {job_number &&
                                                <li><i className="icon-ref"></i>{job_number}</li>
                                                }
                                              {/*    {criteria.start &&
                                                <li><i className="icon-time"></i>{criteria.start}</li>
                                                }
                                                {criteria.degree &&
                                                <li><i className="icon-studies"></i>{criteria.degree}</li>
                                                }*/}
                                            </ul>
                                        </div>
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>

                    <div className="main__content" data-bg="grey" data-color="purple">
                        <div className="container">
                            <div className="main__contentFlex">

                                <div className="main__content-primary">
                                    {youtube_video_id &&
                                    <Video youtubeID={youtube_video_id}/>
                                    }

                                    {mobile ?
                                        (
                                            (textSplit && textSplit.length > 0) && (
                                                textSplit.map((item, index, key) => (
                                                    item.length > 0 &&
                                                    (
                                                        <AccordionContent key={`${index}-${key}`} content={item} index={index}/>
                                                    )

                                                ))
                                            )
                                        )
                                        :
                                        <div dangerouslySetInnerHTML={renderMarkup(text)} className="description"/>
                                    }

                                    {!mobile &&
                                        <>
                                            {postulate.link.url &&
                                                <div className="c-button-ctn">
                                                    <PostulateButton type="button" button={postulate.link} partner={partner}
                                                                     modal={false}><i
                                                        className="icon-long-arrow"></i>{postulate.directLink}</PostulateButton>
                                                </div>
                                            }
                                            {/*  {opening_id &&
                                        <div className="c-button-ctn">
                                            <PostulateButton openingId={opening_id} type="button" button={postulate.link} modal={true}><i
                                                className="icon-long-arrow"></i>{postulate.socialLink}</PostulateButton>
                                        </div>
                                        }*/}
                                        </>
                                    }

                                    {(faq && Object.keys(faq).length > 0) &&
                                        <Faq {...faq} />
                                    }

                                    {(image && image.src) &&
                                        <div className="main__content-primary-img">
                                            <img src={image.src} alt={image.alt} data-admin={image.admin_title} width="680" height="400"/>
                                        </div>
                                    }
                                </div>

                                <div className="main__content-aside">
                                    {((window.location.href.indexOf(process.env.REACT_APP_DOMAIN_1) > -1 || window.location.hostname === "localhost") && lang === 'fr') &&
                                        <div className="c-caask-mobile"/>
                                    }
                                    {!restricted &&
                                        <>
                                            {(job_alert && Object.keys(job_alert).length > 0) &&
                                                <AlertPush {...job_alert} />
                                            }

                                            {/* {(ambassador && Object.keys(ambassador).length > 0) &&
                                        <Ambassador {...ambassador}/>
                                        } */}

                                            {(display_elevator && elevator && Object.keys(elevator).length > 0) &&
                                                <ElevatorPush {...elevator} customPrimaryColor={customPrimaryColor}/>
                                            }

                                            {(cv && Object.keys(cv).length > 0) &&
                                                <ScoringPush {...cv} />
                                            }
                                            {
                                                dynamics_blocs && dynamics_blocs.length > 0 && (
                                                    dynamics_blocs.map((bloc, index) => (
                                                        <AlertPush withIcon={false} key={index} {...bloc} />
                                                    ))
                                                )}
                                            {
                                                (blogposts && Object.keys(blogposts).length > 0 && blogposts.title && active_news) &&
                                                <Blogposts blogposts={blogposts}/>
                                            }
                                        </>
                                    }
                                </div>

                            </div>
                        </div>
                    </div>

                    {!restricted &&
                        <>
                            {(jobs_offer && Object.keys(jobs_offer).length > 0) &&
                                <Offers {...jobs_offer} rerender={update}
                                        updateLikeComponents={updateLikeComponents} map={active_map}/>
                            }
                        </>
                    }

                    <StickyButtons openingId={opening_id} postulate={postulate} liked={liked} postID={post_id}
                                   updateLikeComponents={updateLikeComponents} rerender={update}
                                   restricted={restricted} partner={partner}/>
                </main>
                {haveCustomPrimaryColor && (
                    <style>{`
                        .description h2, .description .accordion-toggle {
                            color: ${customPrimaryColor} !important;
                        }
                        .description li:before {
                            background-color: ${customPrimaryColor} !important;
                        }
                    `}</style>
                )}
            </>
        )
    }

    return isLoaded ? renderPage() : <LoaderPage/>
};

const StickyButtons = ({openingId, partner = '', postulate, liked, postID, updateLikeComponents, rerender, restricted}) => {
    return (
        <div className="c-offer-sticky">
            {postulate.link.url &&
                <PostulateButton type="sticky" button={postulate.link} partner={partner} modal={false}><i
                    className="icon-long-arrow"></i><span>{postulate.directLink}</span></PostulateButton>
            }
            {/*{openingId &&
            <PostulateButton openingId={openingId} type="sticky" button={postulate.link} modal={true}><i
                className="icon-long-arrow"></i><span>{postulate.socialLink}</span></PostulateButton>
            }*/}
            {!restricted &&
                <LikeButton liked={liked} postID={postID} type="sticky" updateLikeComponents={updateLikeComponents}
                            rerender={rerender}/>
            }
        </div>
    )
};

const AccordionContent = ({content, index}) => {
    const contentEl = useRef();
    const [contentShow, setContentShow] = useState(index !== 1);

    let contentTags = content.replace('</h2>', '</h2>§');
    contentTags = contentTags.split('§');

    return (
        <div className="description">
            {contentTags[0] && <div dangerouslySetInnerHTML={{__html: contentTags[0]}} className={`description__title${contentTags[1] ? ' accordion-toggle' : ''}${contentShow ? ' active' : ''}`}
                                    onClick={() => contentTags[1] && setContentShow(!contentShow)}/>}
            {contentTags[1] &&
                    <div dangerouslySetInnerHTML={{__html: contentTags[1]}} className={`description__content accordion-content rc-collapse${contentShow ? ' show' : ''}`} ref={contentEl} style={
                        contentShow ? { height: contentEl.current?.scrollHeight ? contentEl.current.scrollHeight : 'auto' } : { height: "0px" }
                    }/>
            }
        </div>
    )
};

export default TemplateOfferDetails;