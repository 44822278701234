import React from 'react';
import { Helmet } from "react-helmet";
// import ReactGA from "react-ga";
import {useSelector} from "react-redux";
import ParamsUrl from '../../Utils/paramsUrl';

const Metatags = ({ title, description, og_fb_title, og_fb_desc, og_fb_img, og_twitter_title, og_twitter_desc, og_twitter_img, microdatas, indexes }) => {
    // let globalStore = useSelector(state => state.global);
    // let { ga } = globalStore;

    let dataDrivenState = useSelector(state => state.global).dataDriven;
    let referrer = useSelector(state => state.global).referrer;
    let tracking = useSelector(state => state.global).global?.tracking?.data_driven;


    const onHelmetChange = () => {
        
        ParamsUrl.saveUrl();
        if (dataDrivenState === true) {
            let ddsiteutil = window.location.hostname,
                dadriRef = referrer,
                ddr_tracking = null;

            window.dadriRef = dadriRef;

            if(window.lastUrl !== window.location.href){
                window.lastUrl = window.location.href;

                let dataDrivenTags = document.querySelectorAll(".tag-data-driven");

                if (dataDrivenTags.length > 0) {
                    for (let i = 0; i < dataDrivenTags.length; i++) {
                        dataDrivenTags[i].parentNode.removeChild(dataDrivenTags[i]);
                    }
                }

                ddr_tracking = document.createElement('script');
                ddr_tracking.id = "tag-data-driven";
                ddr_tracking.className = "tag-data-driven";
                ddr_tracking.type = 'text/javascript';
                ddr_tracking.src = 'https://tags.data-driven.fr/tags?cch=' + Date.now() + '&h=' + ddsiteutil + '&v=1.03';
                ddr_tracking.setAttribute('title', 'DDAppendScript');
                document.body.appendChild(ddr_tracking);
            }
        }
        if (tracking) {
            const DDHostname = window.location.hostname;
            let existingScript = document.getElementById('data-driven-script');
            if (!existingScript) {
                const DDScript = document.createElement('script');
                DDScript.id = 'data-driven-script';
                DDScript.type = 'text/javascript';
                DDScript.src = `https://tags.data-driven.fr/tags?cch=${Date.now()}&h=${DDHostname}&v=1.03&idCustomer=${tracking.id_customer}&idWebsite=${tracking.id_website}`;
                DDScript.setAttribute('title', `Data Driven Script`);
                document.body.appendChild(DDScript);
            }
        }
    };
    return (
        <Helmet onChangeClientState={onHelmetChange}>
            <title>{title}</title>
            <meta id="meta-description" name="description" content={description} />
            {indexes === 0 &&
                <meta name="robots" content="noindex, nofollow" />
            }
            <meta property="og:url" content={window.location.href} />
            <meta property="og:site_name" content={title} />
            <meta property="og:locale" content="en_US" />
            <meta property="og:locale:alternate" content="fr_FR" />
            <meta property="og:type" content="website" />
            <meta property="og:title" content={title} />
            <meta property="og:description" content={og_fb_desc} />
            <meta property="og:image" content={og_fb_img} />
            <meta property="og:image:secure_url" content={og_fb_img} />
            <meta property="og:image:width" content="166" />
            <meta property="og:image:height" content="88" />
            <meta property="og:image:alt" content={og_fb_title} />
            <meta name="twitter:card" content="summary" />
            <meta name="twitter:title" content={og_twitter_title} />
            <meta name="twitter:description" content={og_twitter_desc} />
            <meta name="twitter:image" content={og_twitter_img} />
            {(microdatas && Object.keys(microdatas).length > 0) &&
                <script type="application/ld+json">{JSON.stringify(microdatas, null, 2)}</script>
            }
        </Helmet>

    );
};

export default Metatags;