import React from "react";

// Hooks
import {Link} from "react-router-dom";

// Components
import LikeButton from "./like-button";
import CLink from "./link";

const Offer = ({post_id, job_number, liked, title, brand, localisation, contract, address, link, updateLikeComponents, rerender, restricted, restrictedBrands, offersPage, customPrimaryColor, company}) => {
    const customColorStyles = !!customPrimaryColor ? {color: customPrimaryColor} : {};
    const customTitleStyles = !!customPrimaryColor ? {color: customPrimaryColor, borderColor: customPrimaryColor} : {};
    const customCHoverStyles = !!customPrimaryColor ? {color: customPrimaryColor, backgroundColor: customPrimaryColor} : {};

    return (
        <div className="c-offer">
            {link.internal ?
                <Link
                    to={`${restricted ? link.url + '?external=true&tax_brands=' + restrictedBrands : link.url}`}
                    title={title}
                    className="c-offerLink">
                    <div className="c-offerDefault">
                        {/*   {offersPage ?
                            <h3 className="title-25 c-offer-title">{title}</h3>
                            :
                            <h3 className="title-25 c-offer-title">{title.length > 45 ? title.substring(0, 40) + '...' : title}</h3>
                        }*/}
                        <h3 className="title-25 c-offer-title" style={customTitleStyles}
                            dangerouslySetInnerHTML={{__html: title}}/>
                        {brand &&
                        <div className="c-offer-brand" style={customColorStyles}>{brand}</div>
                        }
                        {(company && company.image.src) &&
                            <div className="c-offer-brand-img">
                                <img src={company.image.src} alt={company.image.alt}/>
                            </div>
                        }
                        <ul className="c-offer-infos">
                            {contract &&
                            <li><i className="icon-contract"/>{contract}</li>
                            }
                            {job_number &&
                            <li><i className="icon-ref"/>{job_number}</li>
                            }
                            {localisation &&
                            <li><i className="icon-location"/>{localisation}</li>
                            }
                        </ul>
                    </div>
                    <div className="c-offerHover" style={customCHoverStyles}>
                        <div className="c-button" style={customColorStyles}
                             data-custom-color={!!customPrimaryColor}>{link.title}</div>
                    </div>
                </Link>
                :
                <a href={link.url} title={title} className="c-offerLink">
                    <div className="c-offerDefault">
                        <h3 className="title-25 c-offer-title" style={customTitleStyles}
                            dangerouslySetInnerHTML={{__html: title}}/>
                        {brand &&
                        <div className="c-offer-brand" style={customColorStyles}>{brand}</div>
                        }
                        <ul className="c-offer-infos">
                            {contract &&
                            <li><i className="icon-contract"/>{contract}</li>
                            }
                            {job_number &&
                            <li><i className="icon-ref"/>{job_number}</li>
                            }
                            {localisation &&
                            <li><i className="icon-location"/>{localisation}</li>
                            }
                        </ul>
                    </div>
                    <div className="c-offerHover" style={customCHoverStyles}>
                        <div className="c-button" style={customColorStyles}
                             data-custom-color={!!customPrimaryColor}>{link.title}</div>
                    </div>
                </a>
            }
            {!restricted &&
            <LikeButton liked={liked} postID={post_id} updateLikeComponents={updateLikeComponents}
                        rerender={rerender}/>
            }
        </div>
    )
};

export default Offer;