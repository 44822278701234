import React, { useState, useRef, useEffect } from 'react';
import { useHistory } from "react-router-dom";

// Hooks
import { useSelector, useDispatch } from 'react-redux';
import {
    updateElevatorResults,
    updateElevatorShowed, updateFilters,
    updateGlobalStoreLang,
    // updateModalsLoginType,
    // updateModalsOpen
} from '../../Store/action';

// Libraries
import { disableBodyScroll, enableBodyScroll } from 'body-scroll-lock';

// Components
import Menu from './menu';
import CLink from "../../Components/link";
import StaticHeader from "../../Components/static-header";
import Search from "./search";
import Elevator from "../../Components/elevator/elevator";


// Utils
import { getCookie, setCookie, eraseCookie } from "../../Utils/cookies";


const Header = () => {
    const restricted = useSelector(state => state.global).isRestricted;

    let dataGlobal = useSelector(state => state.global).global;
    let { m1199 } = useSelector(state => state.breakpoint);
    let page = useSelector(state => state.page).page;
    let pagebis = useSelector(state => state.page).pagebis;

    const [searchIsOpen, setSearchIsOpen] = useState(false);


    let elevator = useSelector(state => state.global).elevator;

    const cookiesFilters = useSelector(state => state.global).filters;


    // ELEVATOR SUBMIT
    const onElevatorSubmit = (data) => {
        setName(data.firstname);
        setElevatorResults(data);
        setCookie('elevator', JSON.stringify(data), 60);
    };

    // ELEVATOR RESULTS
    let dispatch = useDispatch();
    const globalElevatorResults = useSelector(state => state.global).elevatorResults;
    let [elevatorResults, setElevatorResults] = useState(globalElevatorResults);
    const [name, setName] = useState(null);
    useEffect(() => {
        if (elevatorResults && Object.keys(elevatorResults).length > 0) {
            dispatch(updateElevatorResults(elevatorResults));
            setName(elevatorResults.firstname);
            const { home, ...noHome } = cookiesFilters;
            dispatch(updateFilters(noHome));
        }
    }, [dispatch, elevatorResults]);

    // ELEVATOR RESET
    const [elevatorReady, isElevatorReady] = useState(false);
    const resetElevator = () => {
        setName(null);
        dispatch(updateElevatorResults({}));
        isElevatorReady(false);
        const elevatorCookie = getCookie('elevator');
        if (elevatorCookie) {
            eraseCookie('elevator');
        }
    };

    return (
        <>
            {page &&
                <>
                    {m1199 ?
                        <HeaderMobile {...dataGlobal.header} searchIsOpen={searchIsOpen} page={page} pagebis={pagebis}
                            setSearchIsOpen={setSearchIsOpen}
                            elevator={elevator} name={name}
                            onElevatorSubmit={onElevatorSubmit} resetElevator={resetElevator}
                            isElevatorReady={isElevatorReady} elevatorReady={elevatorReady}
                            restricted={restricted} />
                        :
                        <HeaderDesktop {...dataGlobal.header} page={page} pagebis={pagebis} searchIsOpen={searchIsOpen}
                            setSearchIsOpen={setSearchIsOpen} elevator={elevator} name={name}
                            onElevatorSubmit={onElevatorSubmit} resetElevator={resetElevator}
                            isElevatorReady={isElevatorReady} elevatorReady={elevatorReady}
                            restricted={restricted} />
                    }
                </>
            }
        </>
    )
};
export default Header;

const HeaderDesktop = ({ page, pagebis, logo, menuLabel, menu, search, switch_language, account, postulate, searchIsOpen, setSearchIsOpen, elevator, name, onElevatorSubmit, elevatorReady, isElevatorReady, resetElevator, restricted }) => {
    let dispatch = useDispatch();
    const history = useHistory();
    const { global: { colors, introduction_header, header: { display_elevator } } } = useSelector(state => state.global);

    if(typeof(display_elevator) === "undefined"){
        display_elevator = false;
    }

    const customHeaderData = !!introduction_header && !!introduction_header.image ? {
        content: {
            title: introduction_header?.title,
            imageURL: introduction_header?.image?.src,
            alt: introduction_header?.image?.alt,
        }
    } : null;

    const customPrimaryColor = colors?.primary;
    const haveCustomPrimaryColor = !!customPrimaryColor;
    const haveCustomHeader = !display_elevator && !!customHeaderData;

    const top = useRef();
    const headerEl = useRef();

    // OPEN NAV BANNER DESKTOP
    const [navDesktopVisible, setNavDesktopVisible] = useState(false);

    // SCROLL
    const [headerInitialHeight, setHeight] = useState(0);
    const [scrollDir, setScrollDir] = useState("down");
    const [sticky, isSticky] = useState(false);
    const [stickyMini, isStickyMini] = useState(false);
    const [stickyMiniRemove, isStickyMiniRemove] = useState(false);

    // ELEVATOR
    //const [elevatorIsClosed, isElevatorClosed] = useState(false);
    const [home, isHome] = useState(page === 't-home');
    useEffect(() => {
        isHome(page === 't-home');
    }, [page]);

    useEffect(() => {
        !home && dispatch(updateElevatorShowed(false));
    }, [home]); // eslint-disable-line

    // HOVER TOGGLE ELEVATOR BUTTON
    const [hover, isHover] = useState(false);

    // CLICK TOGGLE ELEVATOR BUTTON
    const handleElevatorButton = () => {
        if (page !== 't-home') {
            history.push(elevator.home_link.url);
        }
        setTimeout(() => {
            dispatch(updateElevatorShowed(true));
            top.current.scrollIntoView();
        }, 400);
    };


    // CLOSE MENU (STICKY)
    const closeMenu = () => {
        setNavDesktopVisible(false);
        setSearchIsOpen(false);
        if (name === null) {
            isElevatorReady(false);
        }
    };

    useEffect(() => {
        let interval = '';
        if (page === 't-home') {
            interval = setInterval(() => {
                if (headerInitialHeight < headerEl.current.clientHeight) {
                    setHeight(headerEl.current.clientHeight);
                } else {
                    //clearInterval(interval)
                }
            }, 50);
        } else {
            interval = setInterval(() => {
                if (headerInitialHeight !== 0 && headerInitialHeight > headerEl.current.clientHeight) {
                    setHeight(headerEl.current.clientHeight);
                } else {
                    // clearInterval(interval)
                }
            }, 50);
        }
        return () => clearInterval(interval);
    }, [page, headerEl]); // eslint-disable-line

    useEffect(() => {
        setHeight(headerEl.current.clientHeight);
    }, [name]);

    useEffect(() => {
        top.current.scrollIntoView();
        if (page === 't-home' || page === 't-404') {
            if (stickyMini) {
                isStickyMiniRemove(true);
                isSticky(false);
                setTimeout(() => {
                    isStickyMini(false);
                    isStickyMiniRemove(false);
                }, 250);
            }
        } else {
            closeMenu();
            isSticky(true);
            setTimeout(() => {
                isStickyMini(true);
            }, 250);
        }
    }, [page]); // eslint-disable-line

    useEffect(() => {
        const threshold = 0;
        let lastScrollY = window.pageYOffset;
        let ticking = false;

        const updateScrollDir = () => {
            const scrollY = window.pageYOffset;

            if (Math.abs(scrollY - lastScrollY) < threshold) {
                ticking = false;
                return;
            }
            setScrollDir(scrollY > lastScrollY ? "down" : "up");
            lastScrollY = scrollY > 0 ? scrollY : 0;
            ticking = false;
        };

        const onScroll = () => {
            if (!ticking) {
                window.requestAnimationFrame(updateScrollDir);
                ticking = true;
            }
        };

        window.addEventListener("scroll", onScroll);

        return () => window.removeEventListener("scroll", onScroll);
    }, []);

    useEffect(() => {
        const onScroll = () => {
            if (((page === 't-home') || (page === 't-404'))) {
                if (scrollDir === 'up') { // up
                    if ((window.pageYOffset < (headerInitialHeight)) && stickyMini) {
                        isStickyMiniRemove(true);
                        isSticky(false);
                        setTimeout(() => {
                            isStickyMini(false);
                            isStickyMiniRemove(false);
                        }, 250);
                    }
                } else if (scrollDir === 'down') { //down
                    if (!sticky) {
                        isStickyMiniRemove(false);
                        if (window.pageYOffset > (headerEl.current.clientHeight)) {
                            // RESTART ELEVATOR
                            if (name === null) {
                                isElevatorReady(false);
                            }
                            isSticky(true);
                            setTimeout(() => {
                                isStickyMini(true);
                            }, 250);
                        }
                    }
                }
            }
        };

        window.addEventListener("scroll", onScroll);

        return () => window.removeEventListener("scroll", onScroll);
    });

    // LAUNCH ELEVATOR FROM ELEVATOR PUSH
    const globalElevatorShowed = useSelector(state => state.global).elevatorShowed;
    useEffect(() => {
        if (globalElevatorShowed) {
            isElevatorReady(true);
            top.current.scrollIntoView();
        } else {
            isElevatorReady(false);
        }
    }, [globalElevatorShowed]); // eslint-disable-line

    return (
        <>
            {restricted ?
                <>
                    <div className="header__size" style={{ minHeight: `${headerInitialHeight}px` }} ref={top}></div>
                    <header
                        className="header" data-menu-visible={false} data-sticky={true}
                        data-mini-remove={false} data-mini={true}
                        ref={headerEl}>
                        <div className="headerMain">
                            <div className="container container--maxi">
                                <div className="headerMainFlex">
                                    <div className="header__logo">
                                        <div>
                                            <img src={logo.image.src} alt={logo.image.alt} />
                                        </div>
                                    </div>
                                    <div className="headerMainFlexRight">
                                        <div className="headerMainFlexRightUser">
                                            <LanguageSwitch {...switch_language} />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="headerBottom">
                            <div className="container container--maxi">
                                <div className="headerBottomFlex">
                                </div>
                            </div>
                        </div>

                    </header>
                </>
                :
                <>
                    <div className="header__size" style={{ minHeight: `${headerInitialHeight}px` }} ref={top}></div>
                    <header
                        className="header" data-menu-visible={navDesktopVisible} data-sticky={sticky}
                        data-mini-remove={stickyMiniRemove} data-mini={stickyMini}
                        data-wave-invert={home}
                        ref={headerEl}
                    >
                        <div 
                            className="headerMain" 
                            data-custom-header={(haveCustomHeader && haveCustomPrimaryColor) || (haveCustomHeader && !sticky)}
                            {...(haveCustomHeader && haveCustomPrimaryColor ? (
                                sticky || (page === "t_home" || pagebis === "flexible") ? {
                                    style: { 
                                        backgroundColor: customPrimaryColor
                                    } 
                                } : {}
                            ) : (
                                haveCustomPrimaryColor ? { 
                                    style: { 
                                        backgroundColor: customPrimaryColor
                                    } 
                                } : {}
                            ))}
                        >
                            <div className="container container--maxi">
                                <div className="headerMainFlex">
                                    <Logo {...logo} closeMenu={() => closeMenu()} />
                                    <div className="headerMainFlexRight" data-searchisopen={searchIsOpen}>
                                        <Menu 
                                            items={menu} 
                                            closeMenu={() => closeMenu()} 
                                            haveCustomHeader={haveCustomHeader || haveCustomPrimaryColor} 
                                            primaryColor={customPrimaryColor || null}     
                                        />
                                        <Search {...search} setSearchIsOpen={setSearchIsOpen} />
                                        <div className="headerMainFlexRightUser">
                                            <LanguageSwitch {...switch_language} />
                                            <UserProfile {...account} />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>


                        {page !== 't-404' && (
                            haveCustomHeader && (page !== "t-home" || pagebis !== "flexible")  ? (
                                // Custom Header
                                <StaticHeader
                                    content={customHeaderData.content}
                                    primaryColor={customPrimaryColor}
                                    sticky={sticky}
                                />
                            ) : (
                                display_elevator && <Elevator 
                                    data={elevator} 
                                    onSubmit={data => {
                                        onElevatorSubmit(data);
                                    }} 
                                    name={name} hover={hover}
                                    reset={resetElevator} 
                                    isHome={home} 
                                    ready={elevatorReady} 
                                    isReady={isElevatorReady} 
                                    haveCustomPrimaryColor={haveCustomPrimaryColor}
                                    customPrimaryColor={customPrimaryColor}
                                    styles={haveCustomPrimaryColor && !haveCustomHeader ? {backgroundColor: customPrimaryColor} : {}}
                                />
                            )      
                        )}


                        <div 
                            className="headerBottom" 
                            data-custom-header={haveCustomHeader && (page !== "t-home" || pagebis !== "flexible")}
                            {...(!haveCustomHeader && !!customPrimaryColor ? { 
                                    style: { 
                                        backgroundColor: customPrimaryColor
                                    } 
                                } : (sticky || (page === "t_home" || pagebis === "flexible")) && haveCustomPrimaryColor
                                ? { 
                                    style: { 
                                        backgroundColor: customPrimaryColor
                                    } 
                                } 
                                : {}
                            )}
                        >
                            <div className="container container--maxi">
                                <div className="headerBottomFlex">
                                    {!haveCustomHeader && display_elevator && (
                                        <button 
                                            type="button" 
                                            className="header__toggleElevator"
                                            onMouseEnter={() => {
                                            isHover(true);
                                            setNavDesktopVisible(false);
                                            }}    
                                            onMouseLeave={() => isHover(false)}
                                            onClick={handleElevatorButton}
                                            data-custom-color={haveCustomPrimaryColor}
                                        >
                                            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 234 50">
                                                <path d="M-785.92,13.45s725-1.07,795.25-1.07,51,32.11,107.53,32.11c22.79,0,43.78-12.21,43.78-12.21s21.4-19.93,51.8-19.91c51.87.05,408.57,0,468.15,0s32.53-48.78,121.48-48.61,217.85,0,217.85,0V-67.78L-785.91-66.71Z" style={{ fill: customPrimaryColor || '#9159a4'}}/>
                                                <path d="M116.86,46.53c-27.34,0-37.36-7.35-48-15.13-11.39-8.35-23.16-17-59.56-17-69.43,0-788,1.06-795.25,1.07h-2V-68.75h2L1021.9-69.82v35.61h-2c-1.29,0-129.92.16-217.85,0h-.46c-47.73,0-61.32,14.49-73.33,27.28-10.31,11-20,21.34-47.69,21.34H594.65c-128.21,0-342.79,0-382.21,0h-.06c-29.21,0-50.21,19.18-50.42,19.38l-.35.26C160.73,34.56,139.9,46.53,116.86,46.53ZM9.33,10.34c37.66,0,50,9,61.86,17.75,10.08,7.39,19.6,14.37,45.67,14.37,20.93,0,40.57-10.7,42.63-11.85,2.28-2.07,23.23-20.27,52.89-20.27h.06c39.42,0,254,0,382.21,0h85.94c26,0,34.72-9.32,44.85-20.1C738-23.13,752.22-38.28,801.61-38.28h.46c81.95.16,199.26,0,215.87,0V-65.75L-783.94-64.68V11.41C-741.75,11.35-58.3,10.34,9.33,10.34Z" style={{ fill: '#fff' }}/>
                                            </svg>
                                        </button>
                                    )}
                                    <ButtonToggleMenu 
                                        menuLabel={menuLabel}
                                        handleClick={() => setNavDesktopVisible(!navDesktopVisible)} 
                                    />
                                    <ButtonPostulate link={postulate} closeMenu={() => closeMenu()} />
                                </div>
                            </div>
                        </div>

                    </header>
                </>
            }
        </>
    )
};

const HeaderMobile = ({ logo, pagebis, menuLabel, menu, search, switch_language, account, postulate, setSearchIsOpen, elevator, page, name, setName, onElevatorSubmit, resetElevator, isElevatorReady, elevatorReady, restricted }) => {
    let dispatch = useDispatch();
    const history = useHistory();

    const top = useRef();
    const headerEl = useRef();

    const { global: { colors, introduction_header, header: { display_elevator } } } = useSelector(state => state.global);

    if(typeof(display_elevator) === "undefined"){
        display_elevator = false;
    }

    const customHeaderData = !!introduction_header && !!introduction_header.image ? {
        content: {
            title: introduction_header?.title,
            imageURL: introduction_header?.image?.src,
            alt: introduction_header?.image?.alt,
        }
    } : null;

    const customPrimaryColor = colors?.primary;
    const haveCustomPrimaryColor = !!customPrimaryColor;
    const haveCustomHeader = !display_elevator && !!customHeaderData;

    // ELEVATOR
    const [home, isHome] = useState(page === 't-home');
    useEffect(() => {
        isHome(page === 't-home');
    }, [page]);

    useEffect(() => {
        !home && dispatch(updateElevatorShowed(false));
    }, [home]); // eslint-disable-line

    // HEADER HEIGHT
    const [headerInitialHeight, setHeight] = useState(0);

    useEffect(() => {
        setTimeout(() => {
            setHeight(headerEl.current.clientHeight);
        }, 600);
    }, [headerEl]);


    // STATE OPEN/CLOSE OF MOBILE MENU
    const [navOpen, setOpen] = useState(false);
    const toggleMenu = () => {
        setOpen(!navOpen);
        if (navOpen) {
            closeMenu();
        } else {
            disableBodyScroll(headerEl);
        }
    };

    // CLOSE MENU
    const closeMenu = () => {
        enableBodyScroll(headerEl);
        setOpen(false);
        // CLOSE SUBMENUS
        const submenuOpened = document.querySelector('.menu__submenu--opened');
        if (submenuOpened) {
            submenuOpened.classList.remove('menu__submenu--opened');
        }
    };

    // ELEVATOR
    const redirectElevator = () => {
        if (page !== 't-home') {
            history.push(elevator.home_link.url);
            dispatch(updateElevatorShowed(true));
        }
    };


    // LAUNCH ELEVATOR FROM ELEVATOR PUSH
    const globalElevatorShowed = useSelector(state => state.global).elevatorShowed;
    useEffect(() => {
        globalElevatorShowed ? isElevatorReady(true) : isElevatorReady(false);
    }, [globalElevatorShowed]); // eslint-disable-line

    const customBgStyles = haveCustomPrimaryColor ? { backgroundColor: `${customPrimaryColor} !important` } : {};
    
    return (
        <>
            {restricted ?
                <>
                    <div className="header__size" style={{ minHeight: `${headerInitialHeight}px` }} ref={top}></div>
                    <header
                        className="header" data-sticky={true} data-mini={true}
                        data-menu-opened={false}
                        ref={headerEl}>
                        <div className="headerMain">
                            <div className="container container--maxi">
                                <div className="headerMainFlex">
                                    <div className="header__logo">
                                        <div>
                                            <img src={logo.image.src} alt={logo.image.alt} />
                                        </div>
                                    </div>
                                    <div className="headerMainFlexRight">
                                    </div>
                                </div>
                            </div>
                        </div>
                    </header>
                </>
                :
                <>
                    <div className="header__size" style={{ minHeight: `${headerInitialHeight}px` }} ref={top}></div>
                    <header
                        className="header" data-sticky={true} data-mini={true}
                        data-menu-opened={navOpen} data-wave-invert={home}
                        ref={headerEl}>

                        <div 
                            className="headerMain"
                            data-custom-header={(haveCustomHeader && haveCustomPrimaryColor)}
                            {...(haveCustomPrimaryColor ? { 
                                    style: { 
                                        backgroundColor: customPrimaryColor
                                    } 
                                } : {}
                            )}
                        >
                            <div className="container container--maxi">
                                <div className="headerMainFlex">
                                    <Logo {...logo} closeMenu={() => closeMenu()} />
                                    <div className="headerMainFlexRight">
                                        <ButtonToggleMenu menuLabel={menuLabel} handleClick={() => toggleMenu()} />
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="headerMobileMenu" style={customBgStyles}>
                            <div className="container" style={customBgStyles}>
                                <div className="headerMobileMenuHeader" style={customBgStyles}>
                                    <div className="headerMainFlexRightUser">
                                        <LanguageSwitch {...switch_language} />
                                        <UserProfile {...account} />
                                    </div>
                                </div>
                                <div className="headerMobileMenuMain" style={customBgStyles}>
                                    <Menu items={menu} closeMenu={() => closeMenu()} haveCustomHeader={haveCustomHeader || haveCustomPrimaryColor} 
                                            primaryColor={customPrimaryColor || null} />
                                    <Search {...search} setSearchIsOpen={setSearchIsOpen} />
                                </div>
                                <div>&nbsp;</div>
                            </div>

                            <div className="headerMobileMenuBottom">
                                <div className="container">
                                    <div className="headerMobileMenuBottomFlex">
                                        {!haveCustomHeader && (
                                            <button 
                                                type="button" 
                                                className="header__toggleElevator"
                                                onClick={() => {
                                                    closeMenu();
                                                    redirectElevator();
                                                    top.current.scrollIntoView();
                                                }}
                                                data-custom-color={haveCustomPrimaryColor}
                                            >
                                                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 234 50">
                                                    <path d="M-785.92,13.45s725-1.07,795.25-1.07,51,32.11,107.53,32.11c22.79,0,43.78-12.21,43.78-12.21s21.4-19.93,51.8-19.91c51.87.05,408.57,0,468.15,0s32.53-48.78,121.48-48.61,217.85,0,217.85,0V-67.78L-785.91-66.71Z" style={{ fill: customPrimaryColor || '#9159a4'}}/>
                                                    <path d="M116.86,46.53c-27.34,0-37.36-7.35-48-15.13-11.39-8.35-23.16-17-59.56-17-69.43,0-788,1.06-795.25,1.07h-2V-68.75h2L1021.9-69.82v35.61h-2c-1.29,0-129.92.16-217.85,0h-.46c-47.73,0-61.32,14.49-73.33,27.28-10.31,11-20,21.34-47.69,21.34H594.65c-128.21,0-342.79,0-382.21,0h-.06c-29.21,0-50.21,19.18-50.42,19.38l-.35.26C160.73,34.56,139.9,46.53,116.86,46.53ZM9.33,10.34c37.66,0,50,9,61.86,17.75,10.08,7.39,19.6,14.37,45.67,14.37,20.93,0,40.57-10.7,42.63-11.85,2.28-2.07,23.23-20.27,52.89-20.27h.06c39.42,0,254,0,382.21,0h85.94c26,0,34.72-9.32,44.85-20.1C738-23.13,752.22-38.28,801.61-38.28h.46c81.95.16,199.26,0,215.87,0V-65.75L-783.94-64.68V11.41C-741.75,11.35-58.3,10.34,9.33,10.34Z" style={{ fill: '#fff' }}/>
                                                </svg>
                                            </button>
                                        )}
                                        <ButtonPostulate link={postulate} closeMenu={() => closeMenu()} />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </header>

                    {page === 't-home' &&
                        <>
                            {pagebis !== 'flexible' && haveCustomHeader ? (
                                // Custom Header
                                <StaticHeader
                                    content={customHeaderData.content}
                                    primaryColor={customPrimaryColor}
                                />
                            ) : (
                                display_elevator && <Elevator 
                                    data={elevator} 
                                    onSubmit={data => onElevatorSubmit(data)} 
                                    name={name}
                                    reset={resetElevator} 
                                    isHome={home} 
                                    ready={elevatorReady} 
                                    isReady={isElevatorReady}
                                    haveCustomPrimaryColor={haveCustomPrimaryColor}
                                    customPrimaryColor={customPrimaryColor}
                                    styles={haveCustomPrimaryColor && !haveCustomHeader ? {backgroundColor: customPrimaryColor} : {}}
                                />
                            )}

                            <div className="headerBottom headerBottom--mobile">
                                <div className="container container--maxi">
                                    <div className="headerBottomFlex">
                                        {!haveCustomHeader && display_elevator && (
                                            <button 
                                                type="button" 
                                                className="header__toggleElevator"
                                                data-custom-color={haveCustomPrimaryColor}
                                            >
                                                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 234 50">
                                                    <path d="M-785.92,13.45s725-1.07,795.25-1.07,51,32.11,107.53,32.11c22.79,0,43.78-12.21,43.78-12.21s21.4-19.93,51.8-19.91c51.87.05,408.57,0,468.15,0s32.53-48.78,121.48-48.61,217.85,0,217.85,0V-67.78L-785.91-66.71Z" style={{ fill: customPrimaryColor || '#9159a4'}}/>
                                                    <path d="M116.86,46.53c-27.34,0-37.36-7.35-48-15.13-11.39-8.35-23.16-17-59.56-17-69.43,0-788,1.06-795.25,1.07h-2V-68.75h2L1021.9-69.82v35.61h-2c-1.29,0-129.92.16-217.85,0h-.46c-47.73,0-61.32,14.49-73.33,27.28-10.31,11-20,21.34-47.69,21.34H594.65c-128.21,0-342.79,0-382.21,0h-.06c-29.21,0-50.21,19.18-50.42,19.38l-.35.26C160.73,34.56,139.9,46.53,116.86,46.53ZM9.33,10.34c37.66,0,50,9,61.86,17.75,10.08,7.39,19.6,14.37,45.67,14.37,20.93,0,40.57-10.7,42.63-11.85,2.28-2.07,23.23-20.27,52.89-20.27h.06c39.42,0,254,0,382.21,0h85.94c26,0,34.72-9.32,44.85-20.1C738-23.13,752.22-38.28,801.61-38.28h.46c81.95.16,199.26,0,215.87,0V-65.75L-783.94-64.68V11.41C-741.75,11.35-58.3,10.34,9.33,10.34Z" style={{ fill: '#fff' }}/>
                                                </svg>
                                            </button>
                                        )}
                                        <ButtonPostulate link={postulate} />
                                    </div>
                                </div>
                            </div>
                        </>
                    }
                </>
            }
        </>
    )
};

/**
 * @component Logo
 */
const Logo = ({ link, image, closeMenu }) => {
    return (
        <div className="header__logo">
            <CLink {...link} handleClick={closeMenu}>
                <img src={image.src} alt={image.alt} />
            </CLink>
        </div>
    )
};

/**
 * @component Changement de langue
 */
const LanguageSwitch = () => {

    const dispatch = useDispatch();
    let lang = useSelector(state => state.global).lang;
    let {path_fr, path_en} = useSelector(state => state.page);

    /*useEffect(() => {
        console.log(path_fr, path_en);
    }, [path_fr, path_en]);*/

    return (
        <>
            {(lang === 'fr' && path_en || lang === 'en' && path_fr) &&
                <div className="header__language">
                    {lang === 'fr' ?
                        <button type="button" onClick={() => dispatch(updateGlobalStoreLang('en'))}>EN</button>
                        :
                        <button type="button" onClick={() => dispatch(updateGlobalStoreLang('fr'))}>FR</button>
                    }
                </div>
            }
        </>
    );
};

/**
 * @component User profile
 */
const UserProfile = ({ link, text }) => {
    // const userID = useSelector(state => state.global).userId;
    // const modalsOpen = useSelector(state => state.modals).openModal;
    //
    // let dispatch = useDispatch();

    // const handleClick = () => {
    //     if (userID) {
    //         dispatch(updateModalsOpen({...modalsOpen, 'login': false}));
    //     } else {
    //         dispatch(updateModalsOpen({...modalsOpen, 'login': true}));
    //         dispatch(updateModalsLoginType('profile'));
    //     }
    // };
    /*  return (
          <>
              {!userID &&
              <div className="header__profile">
                  {/!* <CLink {...link}>
                  <i className="icon-profil"></i><span className="sr-only">{text}</span>
              </CLink>*!/}
                  <button type="button" onClick={() => handleClick()}>
                      <i className="icon-profil"></i><span className="sr-only">{text}</span>
                  </button>

              </div>
              }
          </>
      )*/
    return (
        <></>
    )
};

const ButtonToggleMenu = ({ menuLabel, handleClick }) => {
    return (
        <button type="button" className="header__toggleMenu"
            onClick={handleClick}><span
                className="text">{menuLabel}</span><span></span>
        </button>
    )
};

const ButtonPostulate = ({ link, closeMenu }) => {
    return (
        <div className="header__offerLink">
            <CLink {...link} className={`header__offerLink-item`} handleClick={closeMenu}>{link.title}<i
                className="icon-long-arrow"></i></CLink>
        </div>
    )
};